<template>
  <div>
    <portal to="title-page">
      <h1>Crear Zona</h1>
    </portal>
    <v-card class="mx-auto elevation-1">
      <v-card-title>
        <v-icon large left>{{mdiPlus}}</v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-spacer></v-spacer>
        <v-btn small class="ml-auto" @click="$router.go(-1)">
          <v-icon>{{mdiArrowLeft}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="zonaData.name"
                :rules="nameRules"
                :prepend-icon="mdiText"
                required
                dense
                label="Nombre"
                hint="Ingrese el nombre de la zona"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Precio privado"
                :prepend-icon="mdiCurrencyUsd"
                type="number"
                hint="Tarifa para transporte privado"
                color="primary"
                v-model="zonaData.pricePrivate"
                required
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Precio colectivo"
                :prepend-icon="mdiCurrencyUsd"
                type="number"
                color="primary"
                v-model="zonaData.priceShared"
                hint="Tarifa para transporte colectivo"
                required
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Descripcion</p>
              <vue-editor
                id="description"
                :class="zonaData.description != null && zonaData.description == '' ? 'error' : ''"
                v-model="zonaData.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >Agregar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template> 
<script>
import {
  mdiPlus,
  mdiArrowLeft,
  mdiFlag,
  mdiText,
  mdiClose,
  mdiCurrencyUsd,
} from "@mdi/js";
import { VueEditor } from "vue2-editor";
import Zone from "@/models/Zone";
export default {
  name: "ZoneCreate",
  components: { VueEditor },
  data() {
    return {
      zoneModel: new Zone(),
      ///////////////////////////////////////////////////////
      mdiPlus: mdiPlus,
      mdiArrowLeft: mdiArrowLeft,
      mdiFlag: mdiFlag,
      mdiText: mdiText,
      mdiClose: mdiClose,
      mdiCurrencyUsd: mdiCurrencyUsd,
      validForm: true,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      nameRules: [(v) => !!v || "El nombre es obligatorio"],
      zonaData: {
        name: null,
        priceShared: null,
        pricePrivate: null,
        description: null,
        status: "active",
      },
      descriptionRules: [
        (v) => !!v || "La descripcion es obligatoria",
        (v) =>
          (v && v.length >= 10) ||
          "La descripcion debe ser de al menos 10 caracteres",
      ],
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
      validFormInit: true,
    };
  },
  computed:{
    disableBtnAdd : function() {
      if (this.validFormInit) {
        return false;
      }
      if (!this.zonaData.name || !this.zonaData.priceShared || !this.zonaData.pricePrivate || !this.zonaData.description || !this.validForm) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
  methods: {
    validateForm() {
      this.validFormInit = false;

      this.zonaData.name = this.zonaData.name == null ? '' : this.zonaData.name;
      this.zonaData.pricePrivate = this.zonaData.pricePrivate == null ? '' : this.zonaData.pricePrivate;
      this.zonaData.priceShared = this.zonaData.priceShared == null ? '' : this.zonaData.priceShared;
      this.zonaData.description = this.zonaData.description == null ? '' : this.zonaData.description;
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        let docDataZona = {
          name: this.zonaData.name,
          pricePrivate: this.zonaData.pricePrivate,
          priceShared: this.zonaData.priceShared,
          description: this.zonaData.description,
          code: "",
          status: this.zonaData.status,
        };
        this.zoneModel
          .add(docDataZona)
          .then((resp) => {
            this.zoneModel.update(resp.id, { code: resp.id }).then(() => {
              loading.hide();
              this.showSnackbarSuccess("La zona se agrego correctamente");
              this.$router.push({ path: "/backend/admin/zone" });
            });
          })
          .catch((error) => {
            console.log(error);
            loading.hide();
            this.showSnackbarError(
              "Error al crear la zona, por favor contacte al Administrador si el problema persiste"
            );
          });
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
  },
};
</script>
<style>
#app .quillWrapper.error {
  background-color: transparent !important;
}
.quillWrapper.error > div {
  border-color: #ff5252;
  background-color: transparent;
}
</style>